<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="150px"
    >
      <el-form-item label="部门" prop="departmentId">
        <el-select
          style="width: 100%"
          v-model="dataForm.departmentId"
          placeholder="请选择部门"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="风险编号" prop="number" v-if="dataForm.id">
        <el-input
          v-model="dataForm.number"
          placeholder="风险编号"
          :disabled="isUpdate"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="发起日期" prop="fqDate">
        <el-input v-model="dataForm.fqDate" placeholder="发起日期"></el-input>
      </el-form-item>
      <el-form-item label="风险的类别" prop="dangerType">
        <el-select
          style="width: 100%"
          v-model="dataForm.dangerType"
          placeholder="请选择风险的类别"
        >
          <el-option
            v-for="item in dangerTypeOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="风险描述" prop="dangerDescribe">
        <editor ref="fxmsEditor" v-model="dataForm.dangerDescribe"></editor>
      </el-form-item>
      <el-form-item label="作业步骤" prop="step">
        <editor ref="zybzEditor" v-model="dataForm.step"></editor>
      </el-form-item>
      <el-form-item label="危险源或潜在的事件" prop="dangerSource">
        <el-input
          type="textarea"
          rows="3"
          v-model="dataForm.dangerSource"
          placeholder="危险源或潜在的事件"
        ></el-input>
      </el-form-item>
      <el-form-item label="评价级别" prop="evaluateLevel">
        <el-input
          v-model="dataForm.evaluateLevel"
          placeholder="评价级别"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="风险分级" prop="dangerLevel">
        <el-input
          v-model="dataForm.dangerLevel"
          placeholder="风险分级"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="可能发生的事故类型及后果" prop="consequence">
        <el-input
          type="textarea"
          rows="3"
          v-model="dataForm.consequence"
          placeholder="可能发生的事故类型及后果"
        ></el-input>
      </el-form-item>
      <el-form-item label="管控措施类别" prop="controlType">
        <el-select
          style="width: 100%"
          v-model="dataForm.controlType"
          placeholder="请选择风险的类别"
        >
          <el-option
            v-for="item in controlTypeOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="管控层级" prop="controlLevel">
        <el-input
          v-model="dataForm.controlLevel"
          placeholder="管控层级"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="状态" prop="status">
        <el-select style="width: 100%" v-model="dataForm.status" disabled>
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div>
      <p>修改记录</p>
      <el-input
        type="textarea"
        v-model="dataForm.log"
        disabled
        rows="6"
      ></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!flag"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      departmentOptions: [], // 部门列表
      dangerTypeOptions: ["设施设备", "场所", "作业活动"], // 风险类别选择项
      // 管控措施类别选择项
      controlTypeOptions: [
        "工程技术",
        "管理措施",
        "培训教育",
        "个体防护",
        "应急处置",
      ],
      // 状态选择项
      statusOptions: [
        {
          value: 0,
          label: "已发起风险评估，等待信息员填写",
        },
        {
          value: 1,
          label: "信息员已上传填写结果，等待部门负责人审批",
        },
        {
          value: 2,
          label: "部门负责人已审批，为一般风险，等待质安部管理员审批",
        },
        {
          value: 3,
          label: "质安部管理员已审批，结果为合格",
        },
        {
          value: 4,
          label: "质安部管理员已审批，结果为不合格，重新填写",
        },
        {
          value: 5,
          label: "部门负责人已审批，为高风险，等待质安部负责人审批",
        },
        {
          value: 6,
          label: "质安部负责人已审批，结果为合格",
        },
        {
          value: 7,
          label: "质安部负责人已审批，结果为不合格，重新填写",
        },
        {
          value: 8,
          label: "质安部管理员已归档保存",
        },
      ],
      dataForm: {
        id: 0,
        fqrEid: "",
        fqrName: "",
        fqDate: "",
        departmentId: "",
        liablePerson: "",
        number: "",
        dangerType: "",
        dangerDescribe: "",
        step: "",
        dangerSource: "",
        evaluateLevel: "",
        dangerLevel: "",
        consequence: "",
        controlType: "",
        controlLevel: "",
        status: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      flag: 0,
      isUpdate: false,
      dataRule: {
        fqDate: [
          { required: true, message: "发起日期不能为空", trigger: "blur" },
        ],
        departmentId: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        number: [
          { required: true, message: "风险编号不能为空", trigger: "blur" },
        ],
        dangerType: [
          {
            required: true,
            message: "风险的类别不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 获取部门列表
    getDepartment() {
      this.departmentOptions = [];
      this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
        if (res && res.code === 0) {
          if (res.data.list.length > 0) {
            res.data.list.forEach((item) => {
              this.departmentOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        }
      });
    },
    init(id, flag, isUpdate) {
      // flag：1查看  0填写
      this.isUpdate = isUpdate;
      this.flag = flag;
      this.getDepartment();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.danger.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              // 初始化富文本
              this.$refs.fxmsEditor.destroy();
              this.$refs.fxmsEditor.init(this.dataForm.dangerDescribe);
              this.$refs.zybzEditor.destroy();
              this.$refs.zybzEditor.init(this.dataForm.step);
            }
          });
        } else {
          // 初始化富文本
          this.$refs.fxmsEditor.destroy();
          this.$refs.fxmsEditor.init(this.dataForm.dangerDescribe);
          this.$refs.zybzEditor.destroy();
          this.$refs.zybzEditor.init(this.dataForm.step);
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          if (this.dataForm.id) {
            if (this.isUpdate) {
              // 修改
              this.$http.danger.modify(requestDate).then((data) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: "修改操作成功",
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                      this.$emit("refreshDataList");
                    },
                  });
                } else {
                  this.$message.error(data.msg);
                }
              });
            } else {
              // 填写
              this.$http.danger.update(requestDate).then((data) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: "修改操作成功",
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                      this.$emit("refreshDataList");
                    },
                  });
                } else {
                  this.$message.error(data.msg);
                }
              });
            }
          } else {
            // 新增
            requestDate.id = "";
            this.$http.danger.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
